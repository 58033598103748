import * as React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContentContainer from "../components/ContentContainer"
import ContentParagraph from "../components/ContentParagraph"
import Content from "../components/Content"
import { graphql, useStaticQuery } from "gatsby"
import ContentHeroTitle from "../components/ContentHeroTitle"

const TrustlessPage = () => {
  const { heroBgImage } = useStaticQuery(
    graphql`
      query {
        heroBgImage: file(relativePath: { eq: "hero-bg-2.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

  return (
    <Layout noPadding={true} headerFixed={false}>
      <React.Fragment>
        <SEO title="Trustless technology | Long Rock Capital"/>
        <ContentHeroTitle headerImage={heroBgImage.childImageSharp.fluid} title={"Trustless technology"}/>
        <ContentContainer>
          <Content>
            <ContentParagraph>
              Reduce investment friction with trustless technology.
            </ContentParagraph>
            <ContentParagraph>
              We simplify and increase transparency of asset management by reducing the need for expensive intermediaries.
            </ContentParagraph>
          </Content>
        </ContentContainer>
      </React.Fragment>
    </Layout>
  )
}

export default TrustlessPage